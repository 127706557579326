import classes from './MainPage.module.scss'
import { motion, AnimatePresence } from 'framer-motion'
import { useEffect, useState } from 'react'
// other
import parseCardsPage from '../../../other/parseCardsPage'
import parseFullProfilePage from '../../../other/parseFullProfilePage'
import fetchHtml from '../../../other/fetchHtml'
import animations from '../../../other/animations'
import env from '../../../other/environment'
// svgs
import refreshSvgSrc from '../../../svgs/refresh24.svg'
import filterSvgSrc from '../../../svgs/filter24.svg'
import arrowUpSvgSrc from '../../../svgs/arrowUp24.svg'
// ui
import Overlay from '../../ui/Overlay/Overlay'
import Loader from '../../ui/Loader/Loader'
// nested
import Greet from './Greet/Greet'
import Filters from './Filters/Filters'
import Card from './Card/Card'
import FullProfile from './FullProfile/FullProfile'
import CircleBtn from '../../ui/CircleBtn/CircleBtn'

const MainPage = () => {
  const [profiles, setProfiles] = useState([])
  const [filteredProfiles, setFilteredProfiles] = useState([])
  const [currProfile, setCurrProfile] = useState(null)
  const [isProfilesLoading, setIsProfilesLoading] = useState(true)
  const [isProfileLoading, setIsProfileLoading] = useState(false)
  const isProfilesExist = profiles.length > 1
  const [isGreetBeenShown, setIsGreetBeenShown] = useState(JSON.parse(localStorage.getItem('isGreetBeenShown')))
  const [scrollPosition, setScrollPosition] = useState(0)
  const [filters, setFilters] = useState({
    isVisible: false,
    sortType: 'byIndex',
    isPricesHighlighted: false,
    isPhotosBlured: true,
    isOnlyDuplicate: false,
    isOnlyVideo: false,
    isOnlyMbrOvr: false,
    favBtnClickTrigger: 0 // для отслеживания нажатия кнопки "в/из избранное" в useEffect
  })

  useEffect(() => {
    fetchProfiles()
    loadFilters()
    window.addEventListener('scroll', handleScrollChange)
  }, [])
  // изменять заголовок вкладки при определённых условиях
  useEffect(() => {
    if (currProfile) document.title = currProfile.name
    if (!currProfile) document.title = 'Главная'
    if (isProfileLoading) document.title = `Загрузка анкеты ⌛️`
    if (isProfilesLoading) document.title = 'Загрузка ⌛️'
  }, [isProfileLoading, isProfilesLoading, currProfile])
  // убрать скролл пока открыта анкета
  useEffect(() => {
    if (currProfile) document.body.classList.add('no-scroll')
    else document.body.classList.remove('no-scroll')
  }, [currProfile])
  // убрать скролл пока есть приветственное сообщение
  useEffect(() => {
    if (!isGreetBeenShown) document.body.classList.add('no-scroll')
    else document.body.classList.remove('no-scroll')
  }, [isGreetBeenShown])
  // сохранение фильтров
  useEffect(() => {
    saveFilters()
  }, [filters])
  // фильтровать анкеты при изменении параметров фильтра
  useEffect(() => {
    if (!isProfilesLoading) {
      const sorted = sortProfiles(profiles, filters.sortType)
      const sortedByOnlyDuplicated = filters.isOnlyDuplicate ? findDuplicates(sorted) : sorted
      const sortedByOnlyVideo = filters.isOnlyVideo ? sortedByOnlyDuplicated.filter(profile => profile.isVideoIncluded) : sortedByOnlyDuplicated
      const sortedByOnlyMbrOvr = filters.isOnlyMbrOvr ? sortedByOnlyVideo.filter(profile => profile.preferences.filter(pref => pref === 'Минет без презерватива' || pref === 'Окончание в рот').length > 1) : sortedByOnlyVideo
      const sortedByFavs = sortProfiles(loadProfilesFavProp(sortedByOnlyMbrOvr), 'byFavs')

      setFilteredProfiles(sortedByFavs)

      function loadProfilesFavProp(profiles) {
        return profiles.map(profile => ({
          ...profile,
          isFavorite: (JSON.parse(localStorage.getItem('favorites')) || []).includes(profile.phone.slice(-4))
        }))
      }
    }
  }, [filters, isProfilesLoading])

  // --- dep
  function saveFilters() {
    const  { sortType, isPricesHighlighted, isOnlyDuplicate, isPhotosBlured } = filters
    localStorage.setItem('filters', JSON.stringify({ sortType, isPricesHighlighted, isOnlyDuplicate, isPhotosBlured }))
  }
  function loadFilters() {
    const savedFilters = JSON.parse(localStorage.getItem('filters'))
    if (savedFilters) setFilters((prevFilters) => ({ ...prevFilters, ...savedFilters }))
  }
  async function fetchProfiles() {
    setIsProfilesLoading(true)
    const htmlContent = await fetchHtml(`${env.urls.ORIGINAL}${env.urls.PROXY}${env.urls.SOURCE}${env.urls.NEW}`)
    setIsProfilesLoading(false)
    const profiles = parseCardsPage(htmlContent, env.urls.SOURCE)
    setProfiles(profiles)
  }
  async function fetchProfile(url) {
    setIsProfileLoading(true)
    const htmlContent = await fetchHtml(`${env.urls.ORIGINAL}${env.urls.PROXY}${env.urls.SOURCE}${url}`)
    setIsProfileLoading(false)
    const profile = parseFullProfilePage(htmlContent, env.urls.SOURCE)
    setCurrProfile(profile)
  }
  // --- indep
  function sortProfiles(profiles, type) {
    switch (type) {
      case 'byIndex': return sortByIndex(profiles)
      case 'byPrice': return sortByPrice(profiles, 'asc')
      case 'byAgeAsc': return sortByAge(profiles, 'asc')
      case 'byAgeDesc': return sortByAge(profiles, 'desc')
      case 'byHeightAsc': return sortByHeight(profiles, 'asc')
      case 'byHeightDesc': return sortByHeight(profiles, 'desc')
      case 'byWeightAsc': return sortByWeight(profiles, 'asc')
      case 'byWeightDesc': return sortByWeight(profiles, 'desc')
      case 'byFavs': return sortByFavs(profiles)
      default: return []
    }

    function sortByIndex(profiles) {
      return [...profiles].sort((a, b) => a.index - b.index)
    }
    function sortByPrice(profiles) {
      return [...profiles].sort((a, b) => {
          const aPrice = a.prices[0].apartments === '-' ? Infinity : +a.prices[0].apartments;
          const bPrice = b.prices[0].apartments === '-' ? Infinity : +b.prices[0].apartments;
          return aPrice - bPrice || (+a.prices[0].outcall - +b.prices[0].outcall);
      });
    }
    function sortByAge(profiles, order = 'asc') {
        return [...profiles].sort((a, b) => {
            const ageComparison = a.age - b.age
            return order === 'desc' ? -ageComparison : ageComparison
        })
    }
    function sortByHeight(profiles, order = 'asc') {
        return [...profiles].sort((a, b) => {
            const heightComparison = a.height - b.height
            return order === 'desc' ? -heightComparison : heightComparison
        })
    }
    function sortByWeight(profiles, order = 'asc') {
        return [...profiles].sort((a, b) => {
            const weightComparison = a.weight - b.weight
            return order === 'desc' ? -weightComparison : weightComparison
        })
    }
    function sortByFavs(profiles) {
      return [...profiles].sort((a, b) => (b.isFavorite ? 1 : 0) - (a.isFavorite ? 1 : 0))
    }
  }
  function findDuplicates(profiles) {
    return Object.values(profiles.reduce((acc, profile) => {
      (acc[profile.phone] = acc[profile.phone] || []).push(profile)
      return acc
    }, {})).filter(group => group.length > 1).flat()
  }
  function changeProfileFavProp(profiles, lastPhoneDigits, favValue = null) {
    return profiles.map((profile) => profile.phone.slice(-4) === lastPhoneDigits
    ? { ...profile, isFavorite: favValue ?? !profile.isFavorite}
    : profile)
  }
  function saveFavsToLocalStorage(profiles, lastPhoneDigits) {
    const savedFavs = JSON.parse(localStorage.getItem('favorites') || '[]')
    const targetProfile = profiles.filter(profile => profile.phone.slice(-4) === lastPhoneDigits)[0]
    
    if (targetProfile.isFavorite) {
      const updatedFavs = savedFavs.filter(favLastPhoneDigits => favLastPhoneDigits !== lastPhoneDigits)
      localStorage.setItem('favorites', JSON.stringify(updatedFavs))
    } else {
      savedFavs.push(lastPhoneDigits)
      localStorage.setItem('favorites', JSON.stringify(savedFavs))
    }
  }
  // ---

  // --- хэндлеры
  function handleGreetOkClick() {
    localStorage.setItem('isGreetBeenShown', 'true')
    setIsGreetBeenShown(true)
  }
  async function handleProfileClick(url) {
    fetchProfile(url)
  }
  function handleWrapperClick() {
    if (filters.isVisible) handleFiltersVisibleChange(false)
  }
  function handleFilterBtnClick(e) {
    e.stopPropagation()
    handleFiltersVisibleChange(!filters.isVisible)
  }
  function handleRefreshBtnClick() {
    fetchProfiles()
  }
  function handleArrowUpBtnClick() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
  function handleToFavsClick(e, lastPhoneDigits) {
    e.stopPropagation()
    setFilteredProfiles(prevProfiles => sortProfiles(changeProfileFavProp(prevProfiles, lastPhoneDigits), 'byFavs'))
    setFilters({ ...filters, favBtnClick: filters.favBtnClick++ })
    saveFavsToLocalStorage(filteredProfiles, lastPhoneDigits)
  }
  function handleScrollChange() {
    setScrollPosition(window.scrollY)
  }
  function handleFiltersVisibleChange(newValue) {
    setFilters((prevFilters) => ({ ...prevFilters, isVisible: newValue }));
  }
  function handleSortTypeChange(newValue) {
    setFilters({ ...filters, sortType: newValue })
  }
  function handleIsPricesHighlightedChange(newValue) {
    setFilters({ ...filters, isPricesHighlighted: newValue })
  }
  function handleIsPhotosBluredChange(newValue) {
    setFilters({ ...filters, isPhotosBlured: newValue })
  }
  function handleIsOnlyDuplicateChange(newValue) {
    setFilters({ ...filters, isOnlyDuplicate: newValue })
  }
  function handleIsOnlyVideoChange(newValue) {
    setFilters({ ...filters, isOnlyVideo: newValue })
  }
  function handleIsOnlyMbrOvrChange(newValue) {
    setFilters({ ...filters, isOnlyMbrOvr: newValue })
  }
  // ---

  return <>
    {isProfilesLoading
    ? <Overlay bg='rgba(0, 0, 0, 0)' {...animations.overlay}><Loader /></Overlay>
    : <div
      className={classes.wrapper}
      onClick={handleWrapperClick}
    >
      <AnimatePresence>
        {/* приветственное сообщение */}
        {!isGreetBeenShown ? <Greet onOkClick={handleGreetOkClick} /> : null}

        {/* открытая анкета */}
        {isProfileLoading && <Overlay {...animations.overlay}><Loader /></Overlay>}
        {currProfile && <Overlay
          onClick={() => setCurrProfile(null)}
          {...animations.overlayWhithoutInitial}
        >
          <FullProfile
            onClick={(e) => e.stopPropagation()}
            onCloseBtnClick={() => setCurrProfile(null)}
          >{currProfile}</FullProfile>
        </Overlay>}
      </AnimatePresence>

      {/* главная */}
      {isProfilesExist
      ? <div>
        {/* шапка */}
        <div className={classes.header}>
          <motion.h1
            className={classes.title}
            onClick={ () => window.open(env.urls.SOURCE, '_blank') }
            {...animations.title}
          >dsgomsk</motion.h1>
        </div>

        {/* анкеты */}
        <div className={classes.profiles}>
          {filteredProfiles?.map((profile, index) => <Card
              key={index}
              profile={profile}
              isPricesHighlighted={filters.isPricesHighlighted}
              isPhotosBlured={filters.isPhotosBlured}
              allDuplicates={filteredProfiles}
              onClick={() => handleProfileClick(profile.link)}
              onToFavsClick={handleToFavsClick}
              initial={animations.profiles.initial}
              animate={animations.profiles.animate(index)}
          />)}
        </div>

        {/* боковая панель */}
        <div className={classes.sidebar}>
          <div className={classes.sidebarTop}>
            <CircleBtn onClick={handleFilterBtnClick}>
              <img src={filterSvgSrc} alt='F' />
            </CircleBtn>
            <CircleBtn onClick={handleRefreshBtnClick}>
              <img src={refreshSvgSrc} alt='R' />
            </CircleBtn>
          </div>
          <div className={classes.sidebarBottom}>
            <AnimatePresence>
              {scrollPosition > 500 && <CircleBtn
                className={classes.arrowUp}
                onClick={handleArrowUpBtnClick}
                {...animations.overlay}
              >
                <img src={arrowUpSvgSrc} alt='U' />
              </CircleBtn>}
            </AnimatePresence>
          </div>
        </div>

        {/* фильтры */}
        <Filters
          filters={filters}
          onFiltersVisibleChange={handleFiltersVisibleChange}
          onSortTypeChange={handleSortTypeChange}
          onIsPricesHighlightedChange={handleIsPricesHighlightedChange}
          onIsPhotosBluredChange={handleIsPhotosBluredChange}
          onIsOnlyDuplicateChange={handleIsOnlyDuplicateChange}
          onIsOnlyVideoChange={handleIsOnlyVideoChange}
          onIsOnlyMbrOvrChange={handleIsOnlyMbrOvrChange}
        />
      </div>
      : <Overlay bg='rgba(0, 0, 0, 0)'>
        <motion.div className={classes.errorBox} {...animations.overlay}>
          <p>Количество анкет равно нулю.</p>
          <p>Что-то пошло не так. 😞</p>
          <CircleBtn
            style={{ marginTop: 8 }}
            onClick={handleRefreshBtnClick}
          >
            <img src={refreshSvgSrc} alt='R' />
          </CircleBtn>
        </motion.div>
      </Overlay>}
    </div>}
  </>
}

export default MainPage
