import classes from './Card.module.scss'
import { motion } from 'framer-motion'
import getAgeWord from '../../../../other/utils'
// svgs
import toFavSvg from '../../../../svgs/heartPlus14.svg'
import fromFavSvg from '../../../../svgs/heartMinus14.svg'
import readMoreSvg from '../../../../svgs/readMore12.svg'

const Card = ({
  profile,
  isPricesHighlighted = false,
  isPhotosBlured = false,
  allDuplicates,
  onToFavsClick,
  ...rest
}) => {
  const apartmentsPrice = profile.prices[0].apartments !== '-' ? profile.prices[0].apartments : null
  const outcallPrice = profile.prices[0].outcall !== '-' ? profile.prices[0].outcall : null
  const onlyProfileDuplicates = allDuplicates?.filter((duplicate) => duplicate.phone === profile.phone)?.filter((duplicate) => duplicate.index !== profile.index) ?? null
  const duplicatesIndexes = onlyProfileDuplicates ? onlyProfileDuplicates.map((duplicate) => `#${duplicate.index + 1}`).join(' ') : null
  const isPricesBoxFull = apartmentsPrice && outcallPrice && profile.isExpressIncluded
  const isScreenWidthLow = window.innerWidth <= 600
  const isMbrOvrIncluded = profile.preferences.filter(pref => pref === 'Минет без презерватива' || pref === 'Окончание в рот').length > 1

  function getPriceColorClass(price) {
    if (!isPricesHighlighted) return null

    return price < 3000 ? 'lPrice'
    : price >= 3000 && price <= 5000 ? 'mPrice'
    : price > 5000 ? 'hPrice'
    : ''
  }
  function trimString(str, length, endText) {
    return str.length > length ? (<span>{str.trim().slice(0, length) + '..'} {endText}</span>) : str.trim()
  }

  return <>
    <motion.div className={classes.wrapper} {...rest}>
      <div className={classes.photoContainer}>
        <img
          className={`${classes.photo} ${isPhotosBlured && 'blur'}`}
          src={profile.photo}
          alt=''
        />
      </div>

      <div className={classes.info}>
        <h2 className={classes.name}>{trimString(profile.name, isScreenWidthLow ? 10 : 15)}</h2>
        <p className={classes.phone}>
          {profile.phone + ' '}
          <span className={classes.duplicatesIndexes}>
            {duplicatesIndexes + ' '}
          </span>
            <span
              className={classes.favBtn}
              onClick={(e) => onToFavsClick(e, profile.phone.slice(-4))}
            >
              {!profile.isFavorite
              ? <img src={toFavSvg} alt='ToFav' />
              : <img src={fromFavSvg} alt='FromFav' />}
            </span>
          </p>
        <p className={classes.anthropometry}>
          {getAgeWord(+profile.age)}
          <span className={classes.splitter} />
          {profile.height} см
          <span className={classes.splitter} />
          {profile.weight} кг</p>
        <div className={classes.pricesBox}>
          {apartmentsPrice && <p className={`${classes.price} ${classes[getPriceColorClass(apartmentsPrice)]}`}>час {apartmentsPrice}₽</p>}
          {outcallPrice && <p className={`${classes.price} ${classes[getPriceColorClass(outcallPrice)]}`}>взв {outcallPrice}₽</p>}
          {profile.isExpressIncluded && <p className={`${classes.price} ${classes.express}`}>экспресс</p>}
          {isMbrOvrIncluded && <p className={`${classes.price}`}>МБР + ОВР</p>}
          {profile.isVideoIncluded && <p className={`${classes.price}`}>видео</p>}
        </div>
        <p className={classes.description}>
          {trimString(profile.description, (isPricesBoxFull && isScreenWidthLow) ? 170 : 200, <img src={readMoreSvg} alt='ReadMore' />)}
        </p>
      </div>

      <div className={classes.index}>#{profile.index + 1}</div>
    </motion.div>
  </>
}

export default Card
