import classes from './FullProfile.module.scss'
import { useState } from 'react'
import animations from '../../../../other/animations'
import Box from '../../../ui/Box/Box'
// nested
import Slider from './Slider/Slider'
import Info from './Info/Info'
import Comments from './Comments/Comments'
// ui
import Button from '../../../ui/Button/Button'

const FullProfile = ({ children, isLoading, onCloseBtnClick, className, ...rest }) => {
  const [showVideo, setShowVideo] = useState(false)
  const isCommentsExist = children.comments.length > 0
  const isVideoIncluded = !children.videoSrc.endsWith('undefined')
  
  return <>
    <Box
      className={`${classes.wrapper} ${className}`}
      isCloseBtnExists={true}
      onClose={onCloseBtnClick}
      {...animations.profile}
      {...rest}
    >
      <h1 className={classes.name}>{children.name}</h1>
      <h3 className={classes.phone}>{children.phone}</h3>
      <Slider className={classes.slider}>{children.photosSrc}</Slider>
      {isVideoIncluded && <Button className={classes.showVideoBtn} onClick={() => setShowVideo(!showVideo)}>{!showVideo ? 'показать видео' : 'скрыть видео'}</Button>}
      {showVideo && <video className={classes.video} src={children.videoSrc} controls loop muted autoPlay></video>}
      <p className={classes.description}>{children.description.length > 0 ? children.description : 'Описание отсутствует. 😞'}</p>
      <Info profile={children} />
      {isCommentsExist && <Comments profileName={children.name}>{children.comments}</Comments>}
    </Box>
  </>
}

export default FullProfile
