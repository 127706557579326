import classes from './Slider.module.scss'
import { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import animations from '../../../../../other/animations'
import Loader from '../../../../ui/Loader/Loader'

const Slider = ({ children, className, ...rest }) => {
  const imgUrls = children
  const [imgs, setImgs] = useState([])
  const [isImagesLoaded, setIsImagesLoaded] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [photoSize, setPhotoSize] = useState(true)

  useEffect(() => {
    (async () => {
      setImgs(await getImgs(imgUrls))
      setIsImagesLoaded(true)
    })()
  }, [imgUrls])

  function goToPrevious() {
    const isFirstSlide = currentIndex === 0
    const newIndex = isFirstSlide ? imgs.length - 1 : currentIndex - 1
    setCurrentIndex(newIndex)
  }
  function goToNext() {
    const isLastSlide = currentIndex === imgs.length - 1
    const newIndex = isLastSlide ? 0 : currentIndex + 1
    setCurrentIndex(newIndex)
  }
  async function getImgs(urls) {
    const imgPromises = urls.map(url => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = url
        img.onload = () => resolve(img)
        img.onerror = reject
      })
    })

    return await Promise.all(imgPromises)
  }

  return <>
    <div  className={`${classes.slider} ${className}`} {...rest}>
      <AnimatePresence mode='wait'>
        <div key={currentIndex} className={classes.slide}>
          {isImagesLoaded ? <motion.img
            className={classes.img}
            src={imgs[currentIndex]?.src}
            alt={`Image ${currentIndex}`}
            style={ photoSize ? { height: '72vh' } : {} }
            onClick={() => setPhotoSize(!photoSize)}
            {...animations.slider.img}
          /> : <Loader caption={'загрузка фотографий'} />}
        </div>
      </AnimatePresence>

      {isImagesLoaded && <motion.div className={classes.leftArrow} onClick={goToPrevious} {...animations.slider.prevBtn}>&#10094;</motion.div>}
      {isImagesLoaded && <motion.div className={classes.rightArrow} onClick={goToNext} {...animations.slider.nextBtn}>&#10095;</motion.div>}
      {isImagesLoaded && <p className={classes.counter}>{currentIndex + 1} из {imgs.length}</p>}
    </div>
  </>
}

export default Slider
