export default function parseFullProfilePage(html, dosugUrl) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  const nameAgeElement = doc.querySelector('h2')
  const [name, age] = nameAgeElement ? nameAgeElement.textContent.trim().split(' - ') : ''
  const imgElements = doc.querySelectorAll('img')
  const phoneElement = doc.querySelector('h3')
  const descriptionElement = doc.querySelector('.grid-6 p[style*="font-size: 120%"]')
  const preferencesElements = doc.querySelectorAll('.grid-item.grid-5.green ul')
  const expressElement = doc.querySelector('.existInExClass')
  const commentElements = doc.querySelectorAll('.inc_commentdate')

  const profile = {
    name: name?.trim(),
    age: parseInt(age?.replace(' лет', ''), 10),
    phone: phoneElement?.textContent.trim(),
    description: descriptionElement?.textContent.trim(),
    height: parseInt([...doc.querySelectorAll('li')].find(li => li.textContent.includes('Рост:'))?.textContent.match(/(\d+)/)[0] || '0', 10),
    weight: parseInt([...doc.querySelectorAll('li')].find(li => li.textContent.includes('Вес:'))?.textContent.match(/(\d+)/)[0] || '0', 10),
    district: [...doc.querySelectorAll('li')].find(li => li.textContent.includes('Район:'))?.textContent.split(': ')[1] || '',
    hairColor: [...doc.querySelectorAll('li')].find(li => li.textContent.includes('Цвет волос:'))?.textContent.split(': ')[1] || '',
    breastSize: parseInt([...doc.querySelectorAll('li')].find(li => li.textContent.includes('Грудь:'))?.textContent.match(/(\d+)/)[0] || '0', 10),
    photosSrc: Array.from(imgElements).map(img => dosugUrl + img.src.replace(/^.*?(uploads)/, '$1')).filter(src => src.includes('/list')),
    videoSrc: dosugUrl + doc.querySelector('video source')?.getAttribute('src'),
    prices: {
      hour: parseInt([...doc.querySelectorAll('tr')].find(tr => tr.textContent.includes('Час:')).querySelector('td:nth-child(2)')?.textContent.trim() || '0', 10),
      outcallHour: parseInt([...doc.querySelectorAll('tr')].find(tr => tr.textContent.includes('Час:')).querySelector('td:nth-child(3)')?.textContent.trim() || '0', 10)
    },
    express: expressElement?.style.display !== 'none' ?? false,
    preferences: [],
    comments: []
  }

  preferencesElements.forEach(list => {
    const items = list.querySelectorAll('li')
    items.forEach(item => {
      const preferenceText = item.textContent.trim()
      const [preference, extraCharge] = preferenceText.split('+')
      profile.preferences.push({
        preference: preference.trim(),
        extraCharge: extraCharge ? parseInt(extraCharge, 10) : null
      })
    })
  })

  commentElements.forEach(commentElement => {
    const date = commentElement.textContent.trim()
    const name = commentElement.querySelector('.inc_commentname')?.textContent.trim()
    const text = commentElement.nextElementSibling.textContent.trim()
    profile.comments.push({ date, name, text })
  })

  return profile
}
